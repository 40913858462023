body,
div,
p,
a,
span,
table,
tr,
td,
th,
button {
  font-family: 'Inter', sans-serif;
}

.sectionBreak {
  padding-top: 10rem;
}

.firstSectionBreak {
  padding-top: 27rem;
}

#reveal1,
#reveal2,
#reveal3,
#reveal4 {
  z-index: -1;
}

.mySwiper {
  overflow: visible !important;
}

.swiper-slide-shadow {
  background: transparent !important;
  /* backdrop-filter: blur(3px); */
  /* border-radius: 25px; */
}

.swiper-pagination-bullet {
  background: #000 !important;
  opacity: 0.2 !important;
}

.swiper-pagination-bullet-active {
  opacity: 1 !important;
}

.slideWidth {
  max-width: 400px;
  aspect-ratio: 2/3;
}

.phoneInput {
  border: 1px solid #8A8B8F;
  border-radius: 16px;
  background-color: transparent;
  padding: 0rem;
  color: #fff;
  font-weight: 500;
  width: 100%;
  padding-left: 1rem;

}

.phoneInput .PhoneInputInput::placeholder {
  color: #8A8B8F;
  font-weight: 500;
}

.phoneInput .PhoneInputInput {
  background-color: transparent;
  color: #fff;
  font-weight: 500;
  width: 100%;
  padding: 1rem;
  border: none;
  outline: none !important;
}

.sectionBreakT {
  padding-top: 20rem;
}

.heightBoxWrapper {
  height: 420px;
}

.hiddenTitle {
  z-index: -1;
  opacity: 0;
  transform: translate3d(0px, -100px, 0px);

}

.loaderDiv {
  height: 100vh;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.fadeIn {
  animation: 0.25s fadeIn forwards;
}

.fadeOut {
  animation: 0.25s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;

  }

  to {
    opacity: 0;
  }
}

@media (min-width: 2000.98px) {
  .heightBoxWrapper {
    height: 600px;
  }
}

@media (max-width: 1600.98px) {
  .heightBoxWrapper {
    height: 370px;
  }

  .hiddenTitle {
    transform: translate3d(0px, -60px, 0px);

  }

}

@media (max-width: 1399.98px) {
  .sectionBreak {
    padding-top: 9rem;
  }

  .sectionBreakT {
    padding-top: 15rem;
  }

  .heightBoxWrapper {
    height: 400px;
  }

  .hiddenTitle {
    transform: translate3d(0px, -80px, 0px);

  }
}

@media (max-width: 1199.98px) {
  .sectionBreak {
    padding-top: 8rem;
  }

  .sectionBreakT {
    padding-top: 10rem;
  }


}


@media (max-width: 991.98px) {
  .sectionBreak {
    padding-top: 7rem;
  }

  .firstSectionBreak {
    padding-top: 24rem;
  }
}

@media (max-width: 767.98px) {
  .sectionBreak {
    padding-top: 6rem;
  }

  .firstSectionBreak {
    padding-top: 24rem;
  }
}

@media (max-width: 575.98px) {
  .firstSectionBreak {
    padding-top: 19rem;
  }

  .sectionBreak {
    padding-top: 5rem;
  }

  .heightBoxWrapper {
    height: 350px;
  }

  .hiddenTitle {
    transform: translate3d(0px, -55px, 0px);

  }
}